<template>
  <div id="wrap">
    <div
      id="container"
      :style="{
        width: '1920px',
        height: '1080px',
        transform: `scale(${scale.x},${scale.y})`,
      }"
    >
      <video
        class="img-bg"
        poster="../assets/poster.png"
        :src="`${staticUrl}/video/background.mp4`"
        loop
        autoplay
        muted
      ></video>
      <div class="title">安全知识学习数据分析</div>
      <div class="time">{{ time }}</div>
      <div class="content">
        <div class="box">
          <div class="chart-box">
            <div class="t_line_box">
              <i class="t_l_line"></i>
              <i class="l_t_line"></i>
              <i class="t_r_line"></i>
              <i class="r_t_line"></i>
              <i class="l_b_line"></i>
              <i class="b_l_line"></i>
              <i class="r_b_line"></i>
              <i class="b_r_line"></i>
            </div>
            <div class="title">
              <span>课程知识类型分布</span>
            </div>
            <div id="chart1" class="chart"></div>
          </div>
          <div class="chart-box">
            <div class="t_line_box">
              <i class="t_l_line"></i>
              <i class="l_t_line"></i>
              <i class="t_r_line"></i>
              <i class="r_t_line"></i>
              <i class="l_b_line"></i>
              <i class="b_l_line"></i>
              <i class="r_b_line"></i>
              <i class="b_r_line"></i>
            </div>
            <div class="title">
              <span>安全身份验证分析</span>
            </div>
            <div id="chart2" class="chart"></div>
          </div>
          <div class="chart-box">
            <div class="t_line_box">
              <i class="t_l_line"></i>
              <i class="l_t_line"></i>
              <i class="t_r_line"></i>
              <i class="r_t_line"></i>
              <i class="l_b_line"></i>
              <i class="b_l_line"></i>
              <i class="r_b_line"></i>
              <i class="b_r_line"></i>
            </div>
            <div class="title">
              <span>课程学习时段分析</span>
            </div>
            <div id="chart3" class="chart"></div>
          </div>
        </div>
        <div class="box">
          <div class="chart-box">
            <div class="t_line_box">
              <i class="t_l_line"></i>
              <i class="l_t_line"></i>
              <i class="t_r_line"></i>
              <i class="r_t_line"></i>
              <i class="l_b_line"></i>
              <i class="b_l_line"></i>
              <i class="r_b_line"></i>
              <i class="b_r_line"></i>
            </div>
            <div class="title">
              <span>课程学习人员分布</span>
            </div>
            <div id="chart4" class="chart"></div>
          </div>
          <div class="chart-box">
            <div class="t_line_box">
              <i class="t_l_line"></i>
              <i class="l_t_line"></i>
              <i class="t_r_line"></i>
              <i class="r_t_line"></i>
              <i class="l_b_line"></i>
              <i class="b_l_line"></i>
              <i class="r_b_line"></i>
              <i class="b_r_line"></i>
            </div>
            <div class="title">
              <span>课后答题情况分布</span>
            </div>
            <div id="chart5" class="chart"></div>
          </div>
          <div class="chart-box">
            <div class="t_line_box">
              <i class="t_l_line"></i>
              <i class="l_t_line"></i>
              <i class="t_r_line"></i>
              <i class="r_t_line"></i>
              <i class="l_b_line"></i>
              <i class="b_l_line"></i>
              <i class="r_b_line"></i>
              <i class="b_r_line"></i>
            </div>
            <div class="title">
              <span>知识考核情况分布</span>
            </div>
            <div id="chart6" class="chart"></div>
          </div>
        </div>
        <div class="box">
          <div class="chart-box data-summary">
            <div class="t_line_box">
              <i class="t_l_line"></i>
              <i class="l_t_line"></i>
              <i class="t_r_line"></i>
              <i class="r_t_line"></i>
              <i class="l_b_line"></i>
              <i class="b_l_line"></i>
              <i class="r_b_line"></i>
              <i class="b_r_line"></i>
            </div>
            <div class="title">
              <span>数据汇总</span>
            </div>
            <div class="box-content">
              <div class="ul">
                <div class="li">
                  <div class="li-num">
                    {{ reportStatisticObj.studentCount }}
                  </div>
                  <div class="li-title">已注册总人数</div>
                </div>
                <div class="li">
                  <div class="li-num" style="color: #00e41f">
                    {{ reportStatisticObj.authenticatedCount }}
                  </div>
                  <div class="li-title">已认证人数</div>
                </div>
                <div class="li">
                  <div class="li-num">
                    {{ reportStatisticObj.courseLearningCount }}
                  </div>
                  <div class="li-title">学习人次</div>
                </div>
              </div>
              <div class="ul">
                <div class="li">
                  <div class="li-num">
                    {{ reportStatisticObj.learningCount }}
                  </div>
                  <div class="li-title">已学习人数</div>
                </div>
                <div class="li">
                  <div class="li-num" style="color: #ff3333">
                    {{ reportStatisticObj.notLearningCount }}
                  </div>
                  <div class="li-title">未学习人数</div>
                </div>
                <div class="li">
                  <div class="li-num">
                    {{ reportStatisticObj.totalPassExam }}
                  </div>
                  <div class="li-title">考试合格人次</div>
                </div>
              </div>
              <div class="ul">
                <div class="li">
                  <div class="li-num">{{ reportStatisticObj.totalAuth }}</div>
                  <div class="li-title">身份验证总人次</div>
                </div>
                <div class="li">
                  <div class="li-num">
                    {{ reportStatisticObj.totalSuccessfulAuth }}
                  </div>
                  <div class="li-title">验证成功人次</div>
                </div>
                <div class="li">
                  <div class="li-num">
                    {{ reportStatisticObj.totalFailedAuth }}
                  </div>
                  <div class="li-title">验证失败人次</div>
                </div>
              </div>
              <div id="chart7" class="chart"></div>
            </div>
          </div>
          <div class="chart-box span-1">
            <div class="t_line_box">
              <i class="t_l_line"></i>
              <i class="l_t_line"></i>
              <i class="t_r_line"></i>
              <i class="r_t_line"></i>
              <i class="l_b_line"></i>
              <i class="b_l_line"></i>
              <i class="r_b_line"></i>
              <i class="b_r_line"></i>
            </div>
            <div class="title">
              <span>学员考核综合分析</span>
            </div>
            <div id="chart8" class="chart"></div>
          </div>
        </div>
        <div class="box">
          <div class="chart-box">
            <div class="t_line_box">
              <i class="t_l_line"></i>
              <i class="l_t_line"></i>
              <i class="t_r_line"></i>
              <i class="r_t_line"></i>
              <i class="l_b_line"></i>
              <i class="b_l_line"></i>
              <i class="r_b_line"></i>
              <i class="b_r_line"></i>
            </div>
            <div class="title">
              <span>课程学习形式分析</span>
            </div>
            <div id="chart9" class="chart"></div>
          </div>
          <div class="chart-box">
            <div class="t_line_box">
              <i class="t_l_line"></i>
              <i class="l_t_line"></i>
              <i class="t_r_line"></i>
              <i class="r_t_line"></i>
              <i class="l_b_line"></i>
              <i class="b_l_line"></i>
              <i class="r_b_line"></i>
              <i class="b_r_line"></i>
            </div>
            <div class="title">
              <span>课程学习进度分析</span>
            </div>
            <div id="chart10" class="chart"></div>
          </div>
          <div class="chart-box">
            <div class="t_line_box">
              <i class="t_l_line"></i>
              <i class="l_t_line"></i>
              <i class="t_r_line"></i>
              <i class="r_t_line"></i>
              <i class="l_b_line"></i>
              <i class="b_l_line"></i>
              <i class="r_b_line"></i>
              <i class="b_r_line"></i>
            </div>
            <div class="title">
              <span>知识考核时段分析</span>
            </div>
            <div id="chart11" class="chart"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {
  reportStatistic,
  reportCourseKnowledgeTypeStatistics,
  reportAnswerAnalysisStatistics,
  reportKnowledgeAssessmentAnalysisStatistics,
  reportStudentTypeStatistics,
  reportAssessmentResultsStatistics,
  reportLearningContentAnalysisStatistics,
  reportLearningProgressAnalysisStatistics,
  reportLearningPeriodStatistics,
  reportKnowledgeAssessmentPeriodStatistics,
} from "@/API";
import {staticUrl} from "@/API/baseUrl"

export default {
  data() {
    return {
      staticUrl,
      width: 0,
      height: 0,
      chartList: [],
      scale: {
        x: 1,
        y: 1,
      },
      reportStatisticObj: {
        studentCount: "",
        authenticatedCount: "",
        totalSuccessfulAuth: "",
        totalFailedAuth: "",
        totalAuth: "",
        courseLearningCount: "",
        learningCount: "",
        notLearningCount: "",
        totalPassExam: "",
      },
      courseLearningData: [],
      studentKnowledgeAssessmentData: [],
      time: null,
      timer: null,
    };
  },
  mounted() {
    this.loadReportStatistic();
    this.loadSize();
    this.initChart1();
    this.initChart3();
    this.initChart5();
    this.initChart6();
    this.initChart8();
    this.initChart9();
    this.initChart10();
    this.initChart11();

    this.timer = setInterval(() => {
      this.time = new Date().toLocaleString();
    }, 1000);

    window.addEventListener("resize", () => {
      this.loadSize();
    });
  },
  methods: {
    // 数据汇总
    async loadReportStatistic() {
      this.reportStatisticObj = await reportStatistic();
      this.initChart2();
      this.initChart4();
      await this.initChart7();
    },
    loadSize() {
      this.width = innerWidth;
      this.height = innerHeight;
      this.scale.x = this.width / 1920;
      this.scale.y = this.height / 1080;
      document.getElementById("wrap").style.height = this.height + "px";
    },
    async initChart1() {
      let chartDom = document.getElementById("chart1");
      this.chartList[0] = echarts.init(chartDom);
      let data = await reportCourseKnowledgeTypeStatistics();
      let option = {
        tooltip: {
          trigger: "item",
          confine: true,
        },
        series: [
          {
            type: "pie",
            radius: "60%",
            data,
            label: {
              // show: false,
              color: "#ddd",
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  //自定义颜色
                  let colorList = [
                    "#ff6600",
                    "#0099cc",
                    "#e6b800",
                    "#ff3333",
                    "#800080",
                    "#00E41F",
                  ];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      };

      option && this.chartList[0].setOption(option);
    },
    initChart2() {
      let chartDom = document.getElementById("chart2");
      this.chartList[1] = echarts.init(chartDom);
      const data = [
        {
          name: "身份验证成功人次",
          value: this.reportStatisticObj.totalSuccessfulAuth,
        },
        {
          name: "身份验证失败人次",
          value: this.reportStatisticObj.totalFailedAuth,
        },
      ];
      let option = {
        tooltip: {
          trigger: "item",
          confine: true,
        },
        legend: {
          data: ["身份验证成功人次", "身份验证失败人次"],
          bottom: 15,
          textStyle: {
            color: "#fff",
          },
          itemGap: 30,
        },
        series: [
          {
            type: "pie",
            radius: ["45%", "60%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              fontSize: 30,
              position: "center",
              color: "#fff",
              formatter({ percent }) {
                return Math.round(percent) + "%";
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 36,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data,
            itemStyle: {
              normal: {
                color: function (params) {
                  //自定义颜色
                  let colorList = ["#00E41F", "#FF3333"];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      };
      option && this.chartList[1].setOption(option);
      const hideLabel = () => {
        this.chartList[1].setOption({
          series: {
            label: {
              show: false,
            },
          },
        });
      };
      const showLabel = () => {
        this.chartList[1].setOption({
          series: {
            label: {
              show: true,
            },
          },
        });
      };
      this.chartList[1].on("highlight", hideLabel);
      this.chartList[1].on("mouseover", hideLabel);
      this.chartList[1].on("mouseout", showLabel);
      this.chartList[1].on("downplay", showLabel);
    },
    async initChart3() {
      let chartDom = document.getElementById("chart3");
      this.chartList[2] = echarts.init(chartDom);

      let data = await reportLearningPeriodStatistics();
      data = data.map((item) => {
        return {
          x: item.x,
          y: item.y !== 0 ? item.y : null,
        };
      });
      let option = {
        tooltip: {
          trigger: "item",
          confine: true,
        },
        xAxis: {
          type: "category",
          data: data.map((item) => item.x),
          axisLabel: {
            color: "#fff",
            fontSize: 11,
          },
          axisLine: {
            lineStyle: {
              color: "#fff",
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: "#fff",
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff",
            },
          },
        },
        grid: {
          right: 25,
          bottom: 40,
        },
        series: [
          {
            data: data.map((item) => item.y),
            type: "line",
          },
        ],
      };
      option && this.chartList[2].setOption(option);
    },
    initChart4() {
      let chartDom = document.getElementById("chart4");
      this.chartList[3] = echarts.init(chartDom);
      const data = [
        {
          value: this.reportStatisticObj.learningCount,
          name: "已参与学习人数",
        },
        {
          value: this.reportStatisticObj.notLearningCount,
          name: "未参与学习人数",
        },
      ];
      let option = {
        legend: {
          data: data.map((item) => item.name),
          bottom: 10,
          textStyle: {
            color: "#fff",
          },
          itemGap: 30,
        },
        tooltip: {
          trigger: "item",
          confine: true,
        },
        series: [
          {
            type: "pie",
            radius: "55%",
            data,
            label: {
              // show: false,
              color: "#ddd",
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  //自定义颜色
                  let colorList = ["#00E41F", "#FF3333"];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      };
      option && this.chartList[3].setOption(option);
    },
    async initChart5() {
      let chartDom = document.getElementById("chart5");
      this.chartList[4] = echarts.init(chartDom);
      const data = await reportAnswerAnalysisStatistics();
      let option = {
        tooltip: {
          trigger: "item",
          confine: true,
        },
        xAxis: {
          type: "category",
          data: data.map((item) => item.x),
          axisLabel: {
            color: "#fff",
            fontSize: 10,
          },
          axisLine: {
            lineStyle: {
              color: "#fff",
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: "#fff",
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff",
            },
          },
        },
        grid: {
          right: 25,
          bottom: 40,
          left: 40,
        },
        series: [
          {
            data: data.map((item) => item.y),
            type: "bar",
            barWidth: 12,
            label: {
              show: true,
              position: "top",
              color: "#fff",
            },
            itemStyle: {
              color: "#fac858",
              // borderRadius: 5,
            },
          },
        ],
      };
      option && this.chartList[4].setOption(option);
    },
    async initChart6() {
      let chartDom = document.getElementById("chart6");
      this.chartList[5] = echarts.init(chartDom);
      const data = await reportKnowledgeAssessmentAnalysisStatistics();
      let option = {
        tooltip: {
          trigger: "item",
          confine: true,
        },
        xAxis: {
          type: "category",
          data: data.map((item) => item.x),
          axisLabel: {
            color: "#fff",
            fontSize: 10,
          },
          axisLine: {
            lineStyle: {
              color: "#fff",
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: "#fff",
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff",
            },
          },
        },
        grid: {
          right: 25,
          bottom: 40,
          left: 40,
        },
        series: [
          {
            data: data.map((item) => item.y),
            type: "bar",
            barWidth: 12,
            label: {
              show: true,
              position: "top",
              color: "#fff",
            },
            itemStyle: {
              color: "#04b1f8",
              // borderRadius: 5,
            },
          },
        ],
      };
      option && this.chartList[5].setOption(option);
    },
    async initChart7() {
      let chartDom = document.getElementById("chart7");
      this.chartList[6] = echarts.init(chartDom);
      const data = await reportStudentTypeStatistics();
      const typeList = [
        {
          name: "食品安全知识",
          max: this.reportStatisticObj.studentCount,
        },
        {
          name: "燃气安全知识",
          max: this.reportStatisticObj.studentCount,
        },
        {
          name: "工地安全知识",
          max: this.reportStatisticObj.studentCount,
        },
        {
          name: "消防安全知识",
          max: this.reportStatisticObj.studentCount,
        },
        {
          name: "特种设备安全知识",
          max: this.reportStatisticObj.studentCount,
        },
        {
          name: "医药安全知识",
          max: this.reportStatisticObj.studentCount,
        },
      ];

      let option = {
        title: {
          text: "学员类型分布",
          textStyle: {
            color: "#fff",
            lineHeight: 32,
            fontWeight: "normal",
            fontSize: 16,
          },
          backgroundColor: "#2c58a6",
          padding: [0, 30],
          left: 45,
          top: 0,
          borderRadius: 15,
        },
        radar: {
          // shape: 'circle',
          indicator: typeList,
          radius: 95,
          center: ["50%", "55%"],
        },
        tooltip: {
          trigger: "item",
          confine: true,
        },
        series: [
          {
            name: "学员类型分布",
            type: "radar",
            data: [
              {
                value: data.map((item) => item.value),
              },
            ],
          },
        ],
      };
      option && this.chartList[6].setOption(option);
    },
    async initChart8() {
      let chartDom = document.getElementById("chart8");
      this.chartList[7] = echarts.init(chartDom);
      const data = await reportAssessmentResultsStatistics();
      let option = {
        legend: {
          data: ["课后答题", "知识考核"],
          top: 36,
          textStyle: {
            color: "#fff",
          },
          itemGap: 30,
        },
        tooltip: {
          trigger: "item",
          confine: true,
        },
        xAxis: {
          type: "category",
          data: data.filter((el) => el.s === "课后答题").map((item) => item.x),
          axisLabel: {
            color: "#fff",
            fontSize: 10,
          },
          axisLine: {
            lineStyle: {
              color: "#fff",
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: "#fff",
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff",
            },
          },
        },
        grid: {
          right: 25,
          bottom: 40,
          left: 40,
          top: 75,
        },
        series: [
          {
            name: "课后答题",
            data: data
              .filter((el) => el.s === "课后答题")
              .map((item) => item.y),
            type: "bar",
            barWidth: 12,
            label: {
              show: true,
              position: "top",
              color: "#fff",
            },
            itemStyle: {
              color: "#fac858",
            },
          },
          {
            name: "知识考核",
            data: data
              .filter((el) => el.s === "知识考核")
              .map((item) => item.y),
            type: "bar",
            barWidth: 12,
            label: {
              show: true,
              position: "top",
              color: "#fff",
            },
            itemStyle: {
              color: "#04b1f8",
            },
          },
        ],
      };
      option && this.chartList[7].setOption(option);
    },
    async initChart9() {
      let chartDom = document.getElementById("chart9");
      this.chartList[8] = echarts.init(chartDom);
      const data = await reportLearningContentAnalysisStatistics();
      let option = {
        tooltip: {
          trigger: "item",
          confine: true,
        },
        legend: {
          data: data.map((item) => item.name),
          bottom: 15,
          textStyle: {
            color: "#fff",
          },
          itemGap: 30,
        },
        series: [
          {
            type: "pie",
            radius: ["45%", "60%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              fontSize: 36,
              position: "center",
              color: "#fff",
              formatter({ percent }) {
                return Math.round(percent) + "%";
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 36,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data,
            itemStyle: {
              normal: {
                color: function (params) {
                  //自定义颜色
                  let colorList = ["#1f77b4", "#ff7f0e", " #2ca02c"];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      };
      option && this.chartList[8].setOption(option);
      const hideLabel = () => {
        this.chartList[8].setOption({
          series: {
            label: {
              show: false,
            },
          },
        });
      };
      const showLabel = () => {
        this.chartList[8].setOption({
          series: {
            label: {
              show: true,
            },
          },
        });
      };
      this.chartList[8].on("highlight", hideLabel);
      this.chartList[8].on("mouseover", hideLabel);
      this.chartList[8].on("mouseout", showLabel);
      this.chartList[8].on("downplay", showLabel);
    },
    async initChart10() {
      let chartDom = document.getElementById("chart10");
      this.chartList[9] = echarts.init(chartDom);
      const data = await reportLearningProgressAnalysisStatistics();
      const option = {
        legend: {
          data: data.map((item) => item.name),
          textStyle: {
            color: "#fff",
          },
          icon: "circle",
          orient: "vertical",
          top: 50,
          left: 10,
        },
        tooltip: {
          trigger: "item",
          confine: true,
        },
        series: [
          {
            type: "pie",
            radius: "60%",
            center: ["55%", "57%"],
            data,
            label: {
              // show: false,
              color: "#ddd",
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  //自定义颜色
                  const colorList = [
                    "#4e79a7",
                    "#f28e2b",
                    "#e15759",
                    "#76b7b2",
                    "#00E41F",
                  ];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      };

      option && this.chartList[9].setOption(option);
    },
    async initChart11() {
      const chartDom = document.getElementById("chart11");
      this.chartList[10] = echarts.init(chartDom);
      let data = await reportKnowledgeAssessmentPeriodStatistics();
      data = data.map((item) => {
        return {
          x: item.x,
          y: item.y !== 0 ? item.y : null,
        };
      });
      const option = {
        tooltip: {
          trigger: "item",
          confine: true,
        },
        xAxis: {
          type: "category",
          data: data.map((item) => item.x),
          axisLabel: {
            color: "#fff",
            fontSize: 11,
          },
          axisLine: {
            lineStyle: {
              color: "#fff",
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: "#fff",
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff",
            },
          },
        },
        grid: {
          top: 40,
          left: 50,
          right: 25,
          bottom: 30,
        },
        series: [
          {
            data: data.map((item) => item.y),
            type: "line",
          },
        ],
      };
      option && this.chartList[10].setOption(option);
    },
  },
  beforeDestroy() {
    window.onresize = null;
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style lang="scss" scoped>
#container {
  //background: url(../assets/370.jpg) 0 0 / 100% 100%;
  color: #fff;
  transform-origin: 0 0;
  position: relative;

  .img-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: brightness(0.4);
    z-index: -999;
  }

  > .title {
    text-align: center;
    font-size: 28px;
    margin-bottom: 10px;
    height: 70px;
    line-height: 70px;
    color: #fff;
    -webkit-text-stroke: 1px #fff; /*文字描边*/
    background: url(../assets/title.png) center / 100vw 100%;
    letter-spacing: 10px;
  }

  > .time {
    position: absolute;
    right: 35px;
    top: 30px;
    font-size: 20px;
  }

  .content {
    // flex: 1;
    height: 1000px;
    display: grid;
    grid-template-columns: 12fr 12fr 17fr 12fr;
    gap: 20px;
    padding: 0 20px 20px;
    box-sizing: border-box;

    .span-1 {
      flex: none !important;
      height: calc((100% - 48px) / 3);
    }

    .box {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .chart-box {
        flex: 1;
        position: relative;
        border: 1px solid #2c58a6;
        box-shadow: 0 0 10px #2c58a6;

        .t_line_box {
          position: absolute;
          width: 100%;
          height: 100%;

          .t_l_line {
            height: 2px;
            width: 26px;
            left: -1px;
            top: -1px;
          }

          .l_t_line {
            width: 2px;
            height: 24px;
            left: -1px;
            top: -1px;
          }

          .t_r_line {
            height: 2px;
            width: 26px;
            right: -1px;
            top: -1px;
          }

          .r_t_line {
            width: 2px;
            height: 24px;
            right: -1px;
            top: -1px;
          }

          .l_b_line {
            width: 2px;
            height: 24px;
            left: -1px;
            bottom: -1px;
          }

          .b_l_line {
            height: 2px;
            width: 26px;
            left: -1px;
            bottom: -1px;
          }

          .r_b_line {
            width: 2px;
            height: 24px;
            right: -1px;
            bottom: -1px;
          }

          .b_r_line {
            height: 2px;
            width: 26px;
            right: -1px;
            bottom: -1px;
          }

          i {
            background-color: #4788fb;
            box-shadow: 0 0 10px #4788fb;
            position: absolute;
          }
        }

        div.title {
          position: absolute;
          top: -12px;
          width: 100%;

          span {
            font-size: 16px;
            //font-weight: bold;
            line-height: 32px;
            display: inline-block;
            background-color: #2c58a6;
            box-shadow: 0 0 4px #2c58a6;
            padding: 0 30px;
            border-radius: 15px;
          }
        }
      }

      .chart {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.data-summary {
  .box-content {
    margin-top: 40px;

    .ul {
      display: flex;
      justify-content: space-evenly;
      margin: 0 30px 30px;

      .li {
        flex: 1;

        &-title {
          font-size: 16px;
        }

        &-num {
          font-size: 30px;
          font-weight: bold;
          margin-bottom: 15px;
          color: #4788fb;
        }
      }
    }
  }
}

#chart7 {
  height: 300px !important;
}
</style>
