import axios from "axios";
import { baseUrl } from "./baseUrl";

const request = axios.create({
  timeout: 1000 * 120,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});

request.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return response.data;
    } else {
      Promise.reject();
    }
  },
  (error) => {
    console.log(error);
    return Promise.reject();
  }
);

export const reportStatistic = () => {
  return request({
    url: `${baseUrl}/report/statistic`,
    method: "get",
  });
};

export const reportCourseKnowledgeTypeStatistics = () => {
  return request({
    url: `${baseUrl}/report/courseKnowledgeTypeStatistics`,
    method: "get",
  });
};

export const reportAnswerAnalysisStatistics = () => {
  return request({
    url: `${baseUrl}/report/answerAnalysisStatistics`,
    method: "get",
  });
};

export const reportKnowledgeAssessmentAnalysisStatistics = () => {
  return request({
    url: `${baseUrl}/report/knowledgeAssessmentAnalysisStatistics`,
    method: "get",
  });
};

export const reportStudentTypeStatistics = () => {
  return request({
    url: `${baseUrl}/report/studentTypeStatistics`,
    method: "get",
  });
};

export const reportAssessmentResultsStatistics = () => {
  return request({
    url: `${baseUrl}/report/assessmentResultsStatistics`,
    method: "get",
  });
};

export const reportLearningContentAnalysisStatistics = () => {
  return request({
    url: `${baseUrl}/report/learningContentAnalysisStatistics`,
    method: "get",
  });
};

export const reportLearningProgressAnalysisStatistics = () => {
  return request({
    url: `${baseUrl}/report/learningProgressAnalysisStatistics`,
    method: "get",
  });
};

export const reportLearningPeriodStatistics = () => {
  return request({
    url: `${baseUrl}/report/learningPeriodStatistics`,
    method: "get",
  });
};

export const reportKnowledgeAssessmentPeriodStatistics = () => {
  return request({
    url: `${baseUrl}/report/knowledgeAssessmentPeriodStatistics`,
    method: "get",
  });
};
