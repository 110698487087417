import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import { borderBox12 } from "@jiaminghi/data-view";

Vue.use(borderBox12);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
